
$main-bg: #fff;
$default-color: #000;
$default-title-color: #000;
$orange: #f85b32;

$grey: #fbfbfc;
$white: #fff;

$grey-light: #cfdbe6;
$icon-grey: #767d85;

$svg-default: #767d85;

//forms
$placeholder-txt:  #767d85;
$defaul-txt-form:  #767d85;
$bg-inputs-form: transparent;

//trasition
$gTransition: all .3s ease;
$gTransitionLinear: all .2s linear;

//fonts
$fontDefaultMedium: 'gilroymedium',sans-serif;
$fontGilroyBold: 'gilroybold',sans-serif;
$fontGilroySemi: 'gilroysemibold',sans-serif;


////
////** Background color for `<body>`.
//$body-bg:               #fff !default;
////** Global text color on `<body>`.
//$text-color:            $gray-dark !default;
//
////** Global textual link color.
//$link-color:            $brand-primary !default;
////** Link hover color set via `darken()` function.
//$link-hover-color:      darken($link-color, 15%) !default;
////** Link hover decoration.
//$link-hover-decoration: underline !default;

//== Forms
//
//##

////** `<input>` background color
//$input-bg:                       #fff !default;
////** `<input disabled>` background color
//$input-bg-disabled:              $gray-lighter !default;
//
////** Text color for `<input>`s
//$input-color:                    $gray !default;
////** `<input>` border color
//$input-border:                   #ccc !default;
//
////** Default `.form-control` border radius
//// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
//$input-border-radius:            $border-radius-base !default;
////** Large `.form-control` border radius
//$input-border-radius-large:      $border-radius-large !default;
////** Small `.form-control` border radius
//$input-border-radius-small:      $border-radius-small !default;
//
////** Border color for inputs on focus
//$input-border-focus:             #66afe9 !default;
//
////** Placeholder text color
//$input-color-placeholder:        #999 !default;
//
////** Default `.form-control` height
//$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
////** Large `.form-control` height
//$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
////** Small `.form-control` height
//$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;
//
////** `.form-group` margin
//$form-group-margin-bottom:       15px !default;
//
//$legend-color:                   $gray-dark !default;
//$legend-border-color:            #e5e5e5 !default;
//
////** Background color for textual input addons
//$input-group-addon-bg:           $gray-lighter !default;
////** Border color for textual input addons
//$input-group-addon-border-color: $input-border !default;
//
////** Disabled cursor for form controls and buttons.
//$cursor-disabled:                not-allowed !default;

